import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import Navbar from '@/components/marketing/navbar/navbar.js'
import { withTranslation } from 'react-i18next'

class ErrorBoundary extends React.Component {
  // https://nextjs.org/docs/pages/building-your-application/configuring/error-handling#handling-client-errors
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    console.log({ error, info })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hasError && prevProps.children != this.props.children) {
      this.setState({ hasError: false })
    }
  }

  render() {
    const { t } = this.props

    if (this.state.hasError) {
      return (
        <>
          <Navbar />
          <div className='not-found-container'>
            <figure className='not-found-bg'>
              <Image src='/images/marketing/hero-bg.svg' layout='fill' alt='' />
            </figure>
            <div className='not-found-content'>
              <span className='not-found-subhead'>
                {t('common:somethingWrong')}
              </span>
              <h1 className='not-found-title intro tc'>
                {t('components.clientError.heading')}
              </h1>
              <p className='centered not-found-caption'>
                {t('common:contactSupportInstructions')}{' '}
                <a href='mailto:support@sigmaassessments.com'>
                  support@SigmaAssessments.com
                </a>
              </p>
              <div>
                <Link href='/'>
                  <a className='btn btn-secondary'>{t('common:backHome')}</a>
                </Link>
              </div>
            </div>
          </div>
        </>
      )
    }

    return this.props.children
  }
}

export default withTranslation()(ErrorBoundary)
