import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import { AdminAside } from '@/components/admin-aside'
import { CookiePopup } from '@/components/cookie-popup'
import { LoadingWithMessage } from '@/components/loading-with-message'

import { useStore } from '@/util/store'
import {
  useProjects,
  useUser,
  useOrganization,
  useLanguage,
  useAdminTimeout,
} from '@/util/hooks'
import { TEAM_ROLES } from '@/util/consts'
import { AdminTranslationContext } from '@/contexts/AdminTranslationContext'
import { ReportViewOnlyAside } from './report-view-only/report-view-only-aside'
import { resources } from './report-view-only/resourceFixtures'
import { Banner } from './basic/banner'
import Link from 'next/link'

export const LayoutAdmin = ({ children }) => {
  const router = useRouter()
  useAdminTimeout()
  const { projects } = useProjects()
  const {
    organization,
    isOrgLoading,
    usersWaitingActivation,
  } = useOrganization()
  const translation = useTranslation(
    organization?.translationNamespace || 'admin'
  )
  const [
    isWaitingUserBannerDismissed,
    setIsWaitingUserBannerDismissed,
  ] = useState(false)
  const [dismissedUserIds, setDismissedUserIds] = useState(
    JSON.parse(localStorage.getItem('dismissedUserIds')) || []
  )

  const canEditProjects = useStore((state) => state.canEditProjects)
  const setCanEditProjects = useStore((state) => state.setCanEditProjects)
  const setIsBillingEnabled = useStore((state) => state.setIsBillingEnabled)
  const openModal = useStore((state) => state.openModal)

  // DEV NOTE: Temporary - set language to English as default
  const { setLanguage } = useLanguage()
  useEffect(() => {
    setLanguage('en')
  }, [setLanguage])

  const user = useUser()
  const { agreedToPp, agreedToTou, id: userId } = useUser()

  useEffect(() => {
    // gate admin routes depending on user and feature flags
    // revisit this when user roles/permissions are reworked
    const ADMIN_DASHBOARD = '/admin'
    const READ_ONLY_ADMIN_PATHS = [
      ADMIN_DASHBOARD,
      '/admin/technical-support',
      '/admin/org/profile',
      '/admin/search/[searchTerm]',
      '/admin/project/[projectId]/batch/[batchId]/respondent/[respondentId]',
      '/admin/project/[projectId]/batch/[batchId]/respondent/[respondentId]/test-result',
      '/admin/project/[projectId]/batch/[batchId]/respondent/[respondentId]/test-result-v2',
      '/admin/print/reports/sspo',
      '/admin/print/reports/prfr',
      '/admin/print/reports/prfr-police',
      '/admin/print/reports/prfr-optimized',
    ]
    const isAllowedPath = READ_ONLY_ADMIN_PATHS.includes(router.pathname)

    if (user && user.role === TEAM_ROLES.READ_ONLY && !isAllowedPath) {
      router.replace(ADMIN_DASHBOARD)
    }

    const BILLING_URL_BASE = '/admin/org/billing'
    if (
      organization &&
      !organization.isBillingEnabled &&
      router.pathname.startsWith(BILLING_URL_BASE)
    ) {
      router.replace(ADMIN_DASHBOARD)
    }
  }, [router.pathname, user, organization])

  // Check if terms have been updated - if so ask for reconsent
  useEffect(() => {
    const triggerConsentModal = () => {
      const { privacyPolicy, termsOfUnderstanding } = organization

      openModal('NewMemberConsentModal', {
        userId,
        ...(!agreedToPp && { privacyPolicy: privacyPolicy?.text }),
        ...(!agreedToTou && { termsOfUse: termsOfUnderstanding?.text }),
        isReconsent: true,
      })
    }
    if ((!agreedToPp || !agreedToTou) && organization) triggerConsentModal()
  }, [agreedToPp, agreedToTou, openModal, organization, userId])

  useEffect(() => {
    if (!usersWaitingActivation || usersWaitingActivation.length === 0) {
      return
    }

    const waitingUserIds = usersWaitingActivation.map((member) => member.id)
    const newWaitingUserIds = waitingUserIds.filter(
      (id) => !dismissedUserIds.includes(id)
    )

    if (newWaitingUserIds.length > 0) {
      setIsWaitingUserBannerDismissed(false)
    }
  }, [usersWaitingActivation, dismissedUserIds])

  // set org flags
  useEffect(() => {
    if (organization) {
      setCanEditProjects(organization.canEditProjects)
      setIsBillingEnabled(organization.isBillingEnabled)
    }
  }, [organization])

  const onDismissBanner = () => {
    setIsWaitingUserBannerDismissed(true)
    const waitingUserIds = usersWaitingActivation.map((user) => user.id)
    setDismissedUserIds(waitingUserIds)
    localStorage.setItem('dismissedUserIds', JSON.stringify(waitingUserIds))
  }

  const Aside = () => {
    if (!user.isAdmin) {
      return <ReportViewOnlyAside resources={resources} />
    }
    return <AdminAside isViewOnly={!canEditProjects} projects={projects} />
  }

  if (isOrgLoading) return <LoadingWithMessage className='center-full-page' />

  return (
    <AdminTranslationContext.Provider value={translation}>
      <div className='admin-wrapper'>
        {user.isAdmin &&
          usersWaitingActivation.length > 0 &&
          !isWaitingUserBannerDismissed && (
            <Banner
              iconSrc='/images/icons/single-user-checkmark-blue.svg'
              variant='blue'
              onDismiss={onDismissBanner}
            >
              {translation.t('banner.readOnlyPending', {
                count: usersWaitingActivation.length,
              })}
              &nbsp;
              <Link href='/admin/org'>Manage your team here</Link>.
            </Banner>
          )}

        <Aside />
        <main className='admin-content-wrapper'>{children}</main>

        <CookiePopup days={30} referrer='admin' />
      </div>
    </AdminTranslationContext.Provider>
  )
}
